<template>
  <div>
    <!--<div class="top">-->
    <!--<img src="../assets_ch/back.png" alt="" @click="back">-->
    <!--Back-->
    <!--</div>-->
    <div class="header">
      <div class="img">
        <img :src="audioSet.readAudioCoverMobile" alt="" />
      </div>
      <div class="name-num">
        <p>{{ audioSet.audioTitle }}</p>
        <p>
          共<span>{{ audios.length != 0 ? audios.length : 0 }}</span
          >个音频
        </p>
      </div>
    </div>
    <div class="hengtiao"></div>
    <div class="content">
      <div
        class="video-t"
        v-for="(item, index) in audios"
        :key="index"
        @click="audioCli(item)"
      >
        <div class="video-img">
          <img :src="item.readAudioCoverMobile" alt />
        </div>
        <div class="video-font">{{ item.audioTitle }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  name: "audioList",
  data() {
    return {
      audioId: "",
      audios: [],
      audioSet: {},
      // hrefurl: "https://chplus.1atoshi.top/#",
      // hrefurl: "http://10.100.103.204:8181/#",
      // hrefurl:'http://appone.atoshi.cn/#'
    };
  },
  methods: {
    getvideoList() {
      // server.audioSet({ audioSetId: this.audioId }).then((res) => {
      //   this.audioSet = res.data.data.audioSet;
      //   if (res.data.data.audios) {
      //     this.audios = res.data.data.audios;
      //   }
      // });
      let that = this,
        data = {
          audioSetId: this.audioId
        }
      $.ajax({
        type: "post", //提交方式
        url: "https://official.juhaowu.cn/api/audio/audioSet", //路径
        contentType: "application/json",
        dataType: "json",
        data: JSON.stringify(data), // 数据
        success: function (res) {
          //返回数据根据结果进行相应的处理
          if (res.code == 100) {
            let resData = res.data;

            that.audioSet = resData.audioSet;
            if (resData.audios) {
              that.audios = resData.audios;
            }
          }
        },
      });
    },
    audioCli(item) {
      var userAgent = navigator.userAgent;
      if (item.audioFileUrl) {
        if (/^.+(Mobile\/\w+)\s?$/.test(userAgent)) {
          window.webkit.messageHandlers.getAddressId.postMessage(
            // this.hrefurl + "/audiodetail?audioId=" + item.audioId
            window.location.origin + "/#/audiodetail?audioId=" + item.audioId
          );
        } else {
          this.$router.push({
            path: "/audiodetail",
            query: { audioId: item.audioId },
          });
        }
      } else {
        if (/^.+(Mobile\/\w+)\s?$/.test(userAgent)) {
          window.webkit.messageHandlers.getAddressId.postMessage(
            item.audioLink
          );
        } else {
          window.location.href = item.audioLink;
        }
      }
    },
  },
  created() {
    this.audioId = this.$route.query.audioId;
  },
  mounted() {
    this.getvideoList();
  },
};
</script>

<style scoped>
.header {
  margin-top: 0.45rem;
  margin-left: 0.3rem;
  height: 1.95rem;
  display: flex;
}
.img {
  width: 2rem;
  height: 2rem;
  background: url("https://video2.juhaowu.cn/static-web/mobile_img/common/xiangCeBj.png")
    no-repeat;
  background-size: 100%;
  overflow: hidden;
  position: relative;
}
.img img {
  width: 1.95rem;
  height: auto;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.name-num {
  position: relative;
  margin-left: 0.3rem;
}
.name-num p:first-of-type {
  /*position: absolute;*/
  width: 4.5rem;
  height: auto;
  max-height: 1.34rem;
  /*top:0;*/
  font-size: 0.28rem;
  color: #333333;
  overflow-y: scroll;
}
.name-num p:last-of-type {
  width: 4rem;
  font-size: 0.24rem;
  color: #999999;
  /*position: absolute;*/
  /*bottom:0rem;*/
}
.name-num p:last-of-type span {
  font-size: 0.36rem;
  color: #00a0ea;
  padding: 0 0.1rem;
}
.content {
  padding: 0 0.3rem;
  /*margin-top:.8rem;*/
  display: flex;
  flex-wrap: wrap;
}
.video-t {
  width: 33.33%;
  /*height: 3.55rem;*/
  margin-top: 0.4rem;
}
.video-img {
  width: 2rem;
  height: auto;
  margin: 0 auto;
}
.video-img img {
  width: 100%;
  height: auto;
}
.video-font {
  font-size: 0.26rem;
  color: #666;
  width: 90%;
  margin: 0 auto;
  margin-top: 0.1rem;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.content .video-t:nth-of-type(3n) {
  margin-right: 0;
}
.hengtiao {
  width: 100%;
  height: 0.3rem;
  background: #f7f7f7;
  margin-top: 0.44rem;
}
</style>
